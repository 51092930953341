export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/lavarclick/components/utils/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/lavarclick/components/login/LoginN.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/lavarclick/customers',
    name: 'menu-LV-Customer',
    component: () => import('@/lavarclick/components/customers/CustomersList.vue'),
    meta: {
      // pageTitle: 'Lavar Click',
      // breadcrumb: [
      //   {
      //     text: 'Clientes',
      //   },
      //   {
      //     text: 'Administracion Clientes',
      //     active: true,
      //   },
      // ],
    },
  },  
  {
    path: '/lavarclick/lv-invoice-view',
    name: 'lv-invoice-view',
    component: () => import('@/lavarclick/components/billing/invoice/Invoice.vue'),
    meta: {
      // pageTitle: 'Lavar Click',
      // breadcrumb: [
      //   {
      //     text: 'Facturación',
      //   },
      //   {
      //     text: 'Ver Factura',
      //     active: true,
      //   },
      // ],
    },
  }, 
  {
    path: '/lavarclick/admin-dashboard',
    name: 'admin-dashboard',
    component: () => import('@/lavarclick/components/dashboards/AdminDashboard.vue'),
    meta: {
      // pageTitle: 'Lavar Click',
      // breadcrumb: [
      //   {
      //     text: 'Facturación',
      //   },
      //   {
      //     text: 'Ver Factura',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/lavarclick/mailok',
    name: 'mail-confirmed',
    component: () => import('@/lavarclick/components/login/PageEmailConfirmed.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },    
  {
    path: '/lavarclick/tickets',
    name: 'lv-tickets-view',
    component: () => import('@/lavarclick/components/tickets/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      
    },
  },
  {
    path: '/lavarclick/categories',
    name: 'categories-dashboard',
    component: () => import('@/lavarclick/components/categories/CategoriesDashboard.vue'),
    meta: {
    },
  },
  {
    path: '/lavarclick/service-checkout',
    name: 'service-checkout',
    component: () => import('@/lavarclick/components/payments/ECommerceCheckout.vue'),
    meta: {
     
    },
  },
  {
    path: '/lavarclick/orders-dashboard',
    name: 'lv-orders-dashboard',
    component: () => import('@/lavarclick/components/services/OrdersDashboard.vue'),
    meta: {
     
    },
  },
  {
    path: '/lavarclick/payments-dashboard',
    name: 'lv-payments-dashboard',
    component: () => import('@/lavarclick/components/payments/PaymentsDashboard.vue'),
    meta: {
     
    },
  },
]
